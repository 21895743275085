import React, { FC, useState, useEffect } from "react";
import { TableRowInput, TableRowDropdown } from "../Common";
import { DisplaySettings as Model, ScreenSaver, TemperatureUnit, BoolType } from "./../../store/SimpleWifiStore/models";

export interface DisplaySettingsProps {
    displaySettings: Model,
    saveDisplaySettings: (newDisplaySettings: Model) => void
}

export const DisplaySettings: FC<DisplaySettingsProps> = (props) => {
    const [displaySettings, setDisplaySettings] = useState(props.displaySettings);

    const save = () => props.saveDisplaySettings(displaySettings);
    useEffect(() => {
        setDisplaySettings(props.displaySettings);
    }, [props.displaySettings]);

    return (
        <table className="table table-sm mb-1">
            <tbody>
                <TableRowInput
                    name="Active Screen Brightness"
                    currentValue={displaySettings.brightnessNormal}
                    originalValue={props.displaySettings.brightnessNormal}
                    inputType="number"
                    minValue={1}
                    maxValue={6}
                    onSave={save}
                    onChange={newValue => setDisplaySettings({ ...displaySettings, brightnessNormal: Number(newValue) })} />

                <TableRowInput
                    name="Screensaver Brightness"
                    currentValue={displaySettings.brightnessScreensaver}
                    originalValue={props.displaySettings.brightnessScreensaver}
                    inputType="number"
                    minValue={1}
                    maxValue={6}
                    onSave={save}
                    onChange={newValue => setDisplaySettings({ ...displaySettings, brightnessScreensaver: Number(newValue) })} />

                <TableRowDropdown
                    name="Display"
                    currentValue={Number(displaySettings.displayOn)}
                    originalValue={Number(props.displaySettings.displayOn)}
                    type={BoolType}
                    onChange={newValue => setDisplaySettings({ ...displaySettings, displayOn: !!newValue })}
                    onSave={save} />

                <TableRowDropdown
                    name="Screenlock"
                    currentValue={Number(displaySettings.screenLockEnabled)}
                    originalValue={Number(props.displaySettings.screenLockEnabled)}
                    type={BoolType}
                    onChange={newValue => setDisplaySettings({ ...displaySettings, screenLockEnabled: !!newValue })}
                    onSave={save} />

                <TableRowDropdown
                    name="Display Info"
                    currentValue={displaySettings.screensaver}
                    originalValue={props.displaySettings.screensaver}
                    type={ScreenSaver}
                    onChange={newValue => setDisplaySettings({ ...displaySettings, screensaver: newValue })}
                    onSave={save} />

                <TableRowDropdown
                    name="Temperature Unit"
                    currentValue={displaySettings.temperatureUnit}
                    originalValue={props.displaySettings.temperatureUnit}
                    type={TemperatureUnit}
                    onChange={newValue => setDisplaySettings({ ...displaySettings, temperatureUnit: newValue })}
                    onSave={save} />
            </tbody>
        </table>
    );
};
