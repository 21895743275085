import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Collapse, Container, Navbar, NavbarToggler, NavItem, NavLink } from "reactstrap";
import { ApplicationState } from "../store";
import "./NavMenu.css";
import SimpleWifiServerUrl from "./SimpleWifi/ServerUrl";
import WorkspaceDropdown from "./Workspace/WorkspaceDropdown";
import SimulatorDropdown from "./SimulatorDropdown";
import { Simulators } from "../store/WorkspaceStore/models";
import { LegacyServerUrl } from "./Legacy/ServerUrl";
import { withRedux } from "./Legacy/hoc";
import { signOut } from "../oidcUtilities";

const NavMenu = (props: ApplicationState) => {
    // Component state
    const [isOpen, setIsOpen] = React.useState(false);

    const getNavBarBackground = (simulator: Simulators) => {
        switch (simulator) {
            case Simulators.OWD5:
                return "bg-owd5";
            case Simulators.Senz:
                return "bg-senz";
            case Simulators.UWG4:
                return "bg-uwg4";
            case Simulators.NuHeat:
                return "bg-nuheat";
            case Simulators.SimpleWifi:
                return "bg-dark";
            default:
                return "bg-dark";
        }
    };

    // Render
    return (
        <header>
            <Navbar className={getNavBarBackground(props.workspace.simulator) +
                " navbar-expand-sm navbar-dark navbar-toggleable-sm border-bottom box-shadow mb-3"} light>
                <Container>
                    <SimulatorDropdown />
                    <WorkspaceDropdown />
                    <NavbarToggler onClick={() => setIsOpen(!isOpen)} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={isOpen} navbar>
                        <ul className="navbar-nav flex-grow">
                            <NavItem>
                                <NavLink className="text-white" tag={Link} to="/">Home</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className="text-white" tag={Link} to={{
                                    pathname: "/wizard/" + Simulators[props.workspace.simulator]
                                }}>Create Wizard</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className="text-white" tag={Link} to={{
                                    pathname: "/load/" + Simulators[props.workspace.simulator]
                                }}>Load Wizard</NavLink>
                            </NavItem>
                            <NavItem>
                                {props.workspace.simulator === Simulators.SimpleWifi ?
                                    <SimpleWifiServerUrl />
                                    :
                                    <Link to='#' component={withRedux(LegacyServerUrl, props.workspace.simulator)}/>
                                }
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    to='#'
                                    tag={Link}
                                    className="text-white"
                                    onClick={e => {
                                        e.preventDefault();
                                        signOut();
                                    }}
                                    title="Logout">
                                    Logout
                                </NavLink>
                            </NavItem>
                        </ul>
                    </Collapse>
                </Container>
            </Navbar>
        </header>
    );
};

export default connect(
    (state: ApplicationState) => state
)(NavMenu as any);
