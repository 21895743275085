import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { AlertType } from "../../store/AlertStore/models";
import { ApplicationState } from "../../store";
import { actionCreator } from "../../store/WorkspaceStore/actions";
import { Category, Simulators, Workspace } from "../../store/WorkspaceStore/models";
import { popupAlert } from "../../Utility";
import CategoryModal from "./CategoryModal";
import WorkspaceModal from "./WorkspaceModal";

type Props =
    ApplicationState &
    typeof actionCreator;

export enum ModalStatus {
    Add = "Add",
    Edit = "Edit",
    Clone = "Clone"
}

const WorkspaceDropdown = (props: Props) => {
    // Component state
    const [selectedWorkspace, setSelectedWorkspace] = React.useState(props.workspace.focused);
    const [selectedCategory, setSelectedCategory] = React.useState<Category>({} as Category);
    const [editingWorkspace, setEditingWorkspace] = React.useState<Workspace>({} as Workspace);
    const [modalStatus, setModalStatus] = React.useState(ModalStatus.Add);
    const dispatch = useDispatch();

    //Update the state to reflect a new selected workspace
    React.useEffect(() => {
        setSelectedWorkspace(props.workspace.focused);
    }, [props.workspace, setSelectedWorkspace]);

    //Handle when a new workspace has been selected
    const selectWorkspace = (id: number): void => {
        props.selectWorkspace(id);
        popupAlert(dispatch, "Workspace selected!", "You have successfully selected a new workspace.", AlertType.SUCCESS);
    };

    //Sets values up for the category modal
    const addCategory = (): void => {
        setModalStatus(ModalStatus.Add);
        setSelectedCategory({} as Category);
    };

    //Sets values up for the category modal
    const editCategory = (category: Category): void => {
        setModalStatus(ModalStatus.Edit);
        setSelectedCategory(category);
    };

    //Handles removing a category through the dropdown
    const removeCategory = (category: Category): void => {
        if (window.confirm(
            "Are you sure you want to remove this category? This will remove all workspaces and thermostats from the simulator")) {
            props.removeCategory(category);
            popupAlert(dispatch, "Category removed!", "You have successfully removed the category.", AlertType.SUCCESS);

            //Return back to default if your workspace is in the deleted category
            if (selectedWorkspace.categoryID === category.id) {
                props.selectWorkspace(1);
            }
        }
    };

    //Prepares the workspace modal for editing an existing workspace
    const setupWorkspace = (workspace: Workspace, category: Category): void => {
        setModalStatus(ModalStatus.Edit);
        setEditingWorkspace(workspace);
        setSelectedCategory(category);
    };

    //Sets values up for the workspace modal
    const addWorkspace = (category: Category): void => {
        setModalStatus(ModalStatus.Add);
        setSelectedCategory(category);
        setEditingWorkspace({} as Workspace);
    };

    //Handles removing a workspace through the dropdown
    const removeWorkspace = (workspace: Workspace): void => {
        if (window.confirm(
            "Are you sure you want to remove this workspace? This will remove all of it's thermostats from the simulator")) {
            props.removeWorkspace(workspace);
            popupAlert(dispatch,
                "Workspace removed!",
                "You have successfully removed the workspace.",
                AlertType.SUCCESS);

            if (selectedWorkspace.id === workspace.id) {
                props.selectWorkspace(1);
            }
        }
    };

    //Prepares the workspace modal for cloning an existing workspace
    const setupCloneWorkspace = (workspace: Workspace): void => {
        const category = props.workspace.categories
            .find(c => c.id === workspace.categoryID) as Category;

        setModalStatus(ModalStatus.Clone);
        setEditingWorkspace(workspace);
        setSelectedCategory(category);
    };

    //Helper method to get the category name from its id
    const getCategoryName = (id: number): string => {
        const category = props.workspace.categories
            .find(c => c.id === id);

        return category === undefined ? "" : category.name;
    };

    const getButtonClass = (simulator: Simulators) => {
        switch (simulator) {
            case Simulators.OWD5:
                return "btn-owd5";
            case Simulators.Senz:
                return "btn-senz";
            case Simulators.UWG4:
                return "btn-uwg4";
            case Simulators.NuHeat:
                return "btn-nuheat";
            case Simulators.SimpleWifi:
                return "btn-secondary";
            default:
                return "btn-secondary";
        }
    };

    // Render
    return (
        <div className="dropdown">
            <button className={"btn dropdown-toggle overflow-hidden " + getButtonClass(props.workspace.simulator)} style={{ maxWidth: 375 }} type="button" id="dropdownMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {getCategoryName(selectedWorkspace.categoryID) + " | " + selectedWorkspace.name}
            </button>
            <div className="dropdown-menu overflow-auto" style={{ minWidth: 360, maxWidth: 540, maxHeight: 540 }} aria-labelledby="dropdownMenu">
                <span className="dropdown-header text-wrap overflow-hidden pl-3" style={{ maxWidth: 425 }}>Active: {selectedWorkspace.name} <i className="font-weight-light">{selectedWorkspace.author}</i></span>
                {props.workspace.simulator === Simulators.SimpleWifi &&
                    <button className="btn btn-primary btn-sm ml-3" data-toggle="modal" data-target="#workspaceModal" onClick={() => setupCloneWorkspace(selectedWorkspace)}>Clone</button>
                }
                <div className="dropdown-divider" />
                <div className="d-flex align-items-center">
                    <h4 className="dropdown-header pl-3 pr-1 font-weight-bold">Categories</h4>
                    <svg width="0.8em" height="0.8em" fill="currentColor" className="bi bi-plus btn-outline-success" viewBox="0 0 16 16" data-toggle="modal" data-target="#categoryModal" onClick={() => addCategory()}>
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                </div>
                {props.workspace.categories
                    .filter(category => category.id === 1)
                    .map(category =>
                        <div key={category.id} >
                            <div className="d-flex align-items-center">
                                <h6 className="dropdown-header pr-1 pl-4 font-weight-bold">{category.name}</h6>
                                <svg width="0.9em" height="0.9em" fill="currentColor" className="bi bi-plus mr-1 btn-outline-success" viewBox="0 0 16 16" data-toggle="modal" data-target="#workspaceModal" onClick={() => addWorkspace(category)}>
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                </svg>
                            </div>
                            {category.workspaces.sort(workspace => workspace.id).map(workspace =>
                                <div key={workspace.id} className="d-flex align-items-center pr-4">
                                    <div className="pl-4 pr-1">
                                        <button style={{ maxWidth: 425 }} key={workspace.id} className="dropdown-item text-wrap overflow-hidden" onClick={() => selectWorkspace(workspace.id)}>
                                            {workspace.name} <i className="font-weight-light">{workspace.author}</i>
                                        </button>
                                    </div>
                                    {workspace.id !== 1 &&
                                        <div style={{ minWidth: 40 }}>
                                            <svg width="0.8em" height="0.8em" fill="currentColor" className="bi bi-trash mr-1 btn-outline-danger" viewBox="0 0 16 16" onClick={() => removeWorkspace(workspace)}>
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                            <svg width="0.8em" height="0.8em" fill="currentColor" className="bi bi-pencil-fill mr-1 btn-outline-dark" viewBox="0 0 16 16" data-toggle="modal" data-target="#workspaceModal" onClick={() => setupWorkspace(workspace, category)}>
                                                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                            </svg>
                                        </div>
                                    }
                                </div>
                            )}
                        </div>
                    )}

                {props.workspace.categories
                    .sort(category => category.id)
                    .filter(category => category.id > 1 &&
                    category.simulator === props.workspace.simulator)
                    .map(category =>
                        <div key={category.id}>
                            <div className="d-flex align-items-center">
                                <h6 className="dropdown-header pr-1 pl-4 font-weight-bold">{category.name}</h6>
                                <svg width="0.8em" height="0.8em" fill="currentColor" className="bi bi-trash mr-1 btn-outline-danger" viewBox="0 0 16 16" onClick={() => removeCategory(category)}>
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                    <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                </svg>
                                <svg width="0.8em" height="0.8em" fill="currentColor" className="bi bi-pencil-fill mr-1 btn-outline-dark" viewBox="0 0 16 16" data-toggle="modal" data-target="#categoryModal" onClick={() => editCategory(category) }>
                                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                </svg>
                                <svg width="0.9em" height="0.9em" fill="currentColor" className="bi bi-plus mr-1 btn-outline-success" viewBox="0 0 16 16" data-toggle="modal" data-target="#workspaceModal" onClick={() => addWorkspace(category)}>
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                </svg>
                            </div>
                            {category.workspaces.sort(workspace => workspace.id).map(workspace =>
                                <div key={workspace.id} className="d-flex align-items-center pr-4">
                                    <div className="pl-4 pr-1">
                                        <button style={{ maxWidth: 425 }} key={workspace.id} className="dropdown-item text-wrap overflow-hidden" onClick={() => selectWorkspace(workspace.id)}>
                                            {workspace.name} <i className="font-weight-light">{workspace.author}</i>
                                        </button>
                                    </div>
                                    <div style={{ minWidth: 40 }}>
                                        <svg width="0.8em" height="0.8em" fill="currentColor" className="bi bi-trash mr-1 btn-outline-danger" viewBox="0 0 16 16" onClick={() => removeWorkspace(workspace)}>
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                            <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                        </svg>
                                        <svg width="0.8em" height="0.8em" fill="currentColor" className="bi bi-pencil-fill mr-1 btn-outline-dark" viewBox="0 0 16 16" data-toggle="modal" data-target="#workspaceModal" onClick={() => setupWorkspace(workspace, category)}>
                                            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                        </svg>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
            </div>

            {/* Category modal */}
            <CategoryModal
                parentProps={props}
                category={selectedCategory}
                status={modalStatus} />

            {/* Workspace modal */}
            <WorkspaceModal
                parentProps={props}
                workspace={editingWorkspace}
                category={selectedCategory}
                categories={props.workspace.categories}
                status={modalStatus} />

        </div>
    );
};

export default connect(
    (state: ApplicationState) => state,
    actionCreator,
)(WorkspaceDropdown as any);
