export interface Workspace {
    id: number,
    name: string,
    author: string,
    categoryID: number
}

export interface Category {
    id: number,
    name: string,
    simulator: Simulators,
    workspaces: Workspace[]
}

export interface Workspaces {
    simulator: Simulators,
    categories: Category[],
    focused: Workspace
}

export enum Simulators {
    SimpleWifi = 0,
    NuHeat = 1,
    UWG4 = 2,
    Senz = 3,
    OWD5 = 4
}